import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
/*-----pages-----*/
import Home from "./Home";

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home language="zh" />} />
          {/*<Route path="/en" element={<Home language="en" />} />*/}
          <Route path="*" element={<Navigate to="/" />} />
          {/*<Route path="*" element={<NotFound />} />*/}
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
