import React, { Component } from "react";
import ServiceItem from "../Service/Serviceitem";
import { Fade } from "react-awesome-reveal";

class Service extends Component {
  render() {
    return (
      <section className="service_area_two" id="service">
        <div className="container-fluid">
          <h2 className="title_text_new text-center">
            Our Best Portfolio {"\n"} we are Really Proud for this
          </h2>
          <Fade direction="up" cascade triggerOnce="true">
            <div className="d-grid justify-content-center">
              <ServiceItem
                sText="Website Design"
                sdescription="Certainly! Crafting a compelling bio as a front-end developer involves highlighting your skills, experience,"
                icon="service1"
                classs="one"
              />
              <ServiceItem
                sText="UX Development"
                sdescription="Certainly! Crafting a compelling bio as a front-end developer involves highlighting your skills, experience,"
                icon="service2"
                classs="two"
              />
              <ServiceItem
                sText="Graphic Design"
                sdescription="Certainly! Crafting a compelling bio as a front-end developer involves highlighting your skills, experience,"
                icon="service3"
                classs="three"
              />
              <ServiceItem
                sText="Business Analysis"
                sdescription="Certainly! Crafting a compelling bio as a front-end developer involves highlighting your skills, experience,"
                icon="service4"
                classs="four"
              />
            </div>
          </Fade>
        </div>
      </section>
    );
  }
}

export default Service;
