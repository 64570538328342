import React, { Component } from "react";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

class PitemGrid extends Component {
  render() {
    let { img, title, description } = this.props;
    Fancybox.bind("[data-fancybox]", {});

    return (
      <div className="col-md-6">
        <div className="new_portfolio_item">
          <div className="portfolio_img">
            <img src={require(`../../image/portfolio/${img}`)} alt="" />
            <a
              href={require(`../../image/portfolio/${img}`)}
              data-fancybox="gallery"
            >
              <i className="icon_plus "></i>
            </a>
          </div>
          <div className="portfolio_text">
            <h3>{title}</h3>
            <p>{description}</p>
            <a href="" data-fancybox="gallery">
              <i className="icon_plus "></i>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default PitemGrid;
