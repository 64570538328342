import React, { Component } from "react";
import imag from "../../image/creative_banner.jpg";
import { Fade } from "react-awesome-reveal";

class CreativeBanner extends Component {
  render() {
    let jhonData = this.props.jhonData;
    return (
      <section
        className="creative_banner_two"
        id="home"
        style={{
          width: "100%",
          height: "100%",
          backgroundSize: "cover",
          backgroundImage: `url(${imag})`,
        }}
      >
        <div className="container">
          <Fade direction="up" cascade triggerOnce="true">
            {jhonData.creativeBanner &&
              jhonData.creativeBanner.map((item) => {
                return (
                  <div className="creative_banner" key={item.id}>
                    <h5>
                      {item.subtile}
                      <span> {item.name}</span>
                    </h5>
                    <h2>{item.title}</h2>
                    {/*<a href="./" className="creative_btn">*/}
                    {/*  <span>Let’s Talk</span>*/}
                    {/*  <img*/}
                    {/*    src={require("../../image/arrow-new.png")}*/}
                    {/*    alt="arrow"*/}
                    {/*  />*/}
                    {/*</a>*/}
                  </div>
                );
              })}
          </Fade>
        </div>
      </section>
    );
  }
}
export default CreativeBanner;
