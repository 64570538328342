import React, { Component } from "react";
import anime from "animejs/lib/anime.es.js";
import CounterItem from "./Skill/CounterItem";

class AboutTwo extends Component {
  componentDidMount() {
    function countup(el, target) {
      let data = { count: 0 };
      anime({
        targets: data,
        count: [0, target],
        duration: 2000,
        round: 1,
        delay: 200,
        easing: "easeOutCubic",
        update() {
          el.innerText = data.count.toLocaleString();
        },
      });
    }

    function makeCountup(el) {
      const text = el.textContent;
      const target = parseInt(text, 10);

      const io = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            countup(el, target);
            io.unobserve(entry.target);
          }
        });
      });

      io.observe(el);
    }

    const els = document.querySelectorAll("[data-countup]");

    els.forEach(makeCountup);
  }
  render() {
    let jhonData = this.props.jhonData;
    return (
      <div className="about_area_two" id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex align-items-center">
              <div className="about_content_two">
                {jhonData.creativeAbout &&
                  jhonData.creativeAbout.map((item) => {
                    return (
                      <React.Fragment key={item.id}>
                        <h2>{item.smalltitle}</h2>
                        <p>{item.discription}</p>
                      </React.Fragment>
                    );
                  })}

                <a
                  href="../../image/about_img.png"
                  className="about_btn theme_btn"
                  download
                >
                  Download CV <i className="arrow_right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about_skill">
                <div className="row">
                  <CounterItem
                    col="col-md-6 s_item"
                    CText="1000"
                    pdescription="Awesome Clients"
                  />
                  <CounterItem
                    col="col-md-6 s_item"
                    CText="120"
                    pdescription="Country ‘s  Projects"
                  />
                  <CounterItem
                    col="col-md-6 s_item"
                    CText="4"
                    pdescription="Team Design Lead"
                  />
                  <CounterItem
                    col="col-md-6 s_item"
                    CText="10"
                    pdescription="Project Completed Rate"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutTwo;
