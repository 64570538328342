import React, { Component } from "react";
import PitemGrid from "./PitemGrid";
import Isotope from "isotope-layout/js/isotope";
import ImagesLoaded from "imagesloaded/imagesloaded";

class PortfolioTwo extends Component {
  componentDidMount() {
    var imgLoad = new ImagesLoaded(".grid");

    imgLoad.on("progress", function (instance, image) {
      this.iso = new Isotope(".grid", {
        itemSelector: ".col-md-6",
        layoutMode: "masonry",
      });
    });
  }
  render() {
    let bgcolor = { backgroundColor: "#0C0C0C" };
    var { pClass } = this.props;
    const portfolioData = [
      {
        id: 1,
        img: "pt1.jpg",
        title: "Painting Application",
        description: "Painting",
      },
      {
        id: 2,
        img: "pt2.jpg",
        title: "Morden Application",
        description: "Application",
      },
      {
        id: 3,
        img: "pt3.jpg",
        title: "Web application",
        description: "Website",
      },
      {
        id: 4,
        img: "pt4.jpg",
        title: "Mobile Application",
        description: "Application",
      },
      // Add more items as needed
    ];
    return (
      <section
        className={`portfolio_area ${pClass}`}
        id="portfolio"
        style={bgcolor}
      >
        <div className="container">
          <h2 className="title_text_new" style={{ color: "#fff" }}>
            Our Best Portfolio {"\n"} we are Really Proud for this
          </h2>
          <div className="row grid">
            {portfolioData.map((item) => (
              <PitemGrid key={item.id} {...item} />
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default PortfolioTwo;
