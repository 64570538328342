import React, { Component } from "react";

class FooterText extends Component {
  render() {
    return (
      <div className="text_footer_area">
        <div className="container">
          <a href="./" className="text_btn">
            <span>Let’s Talk</span>
            <div className="creative_btn">
              <img src={require("../image/arrow-new.png")} alt="arrow" />
            </div>
          </a>
        </div>
      </div>
    );
  }
}

export default FooterText;
