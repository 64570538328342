import React from "react";
import Navbar from "./component/Navbar/Navbar";
import CreativeBanner from "./component/Banner/CreativeBanner";
import AboutTwo from "./component/AboutTwo";
import Service from "./component/Service/Service";
import PortfolioTwo from "./component/Portfolio/PortfolioTwo";
import Marqueeitem from "./component/Portfolio/Marqueeitem";
import MarqueeitemTwo from "./component/Portfolio/MarqueeitemTwo";
import Process from "./component/Service/Process";
import TestimonialThree from "./component/Testimonial/TestimonialThree";
import BlogThree from "./component/Blog/BlogThree";
import FooterText from "./component/FooterText";
import jhonData from "./component/jhonData";
import HeightEmulator from "./component/HeightEmulator";
import FooterThree from "./component/FooterThree";

const Home = ({ language }) => {
  return (
    <div className="body_wrapper red_color">
      <Navbar mClass="new_menu" mainlogo="logo-light.png" stickylogo="logo-dark.png" />
      <CreativeBanner jhonData={jhonData} />
      {/*<Marqueeitem pClass="yellow rotate" />*/}
      {/*<AboutTwo jhonData={jhonData} />*/}
      {/*<Service />*/}
      {/*<PortfolioTwo pClass="p_new_area" />*/}
      {/*<Marqueeitem pClass="yellow" />*/}
      {/*<MarqueeitemTwo pClass="black" />*/}
      {/*<Process />*/}
      {/*<TestimonialThree />*/}
      {/*<BlogThree />*/}
      {/*<FooterText />*/}
      {/*<HeightEmulator />*/}
      <FooterThree jhonData={jhonData} />
    </div>
  );
};

export default Home;
