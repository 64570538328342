import React, { Component } from "react";

class ServiceItem extends Component {
  render() {
    let { classs, sText, sdescription, icon } = this.props;
    return (
      <div className="item">
        <div
          className={`service_item text-center wow fadeInUp ${classs}`}
          data-wow-delay="0.1s"
        >
          <img src={require(`../../image/${icon}.png`)} alt="icon" />
          <h3 className="t_color">{sText}</h3>
          <p>{sdescription}</p>
        </div>
      </div>
    );
  }
}
export default ServiceItem;
