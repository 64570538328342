import React, { Component } from "react";
import TestimonialSliderThree from "./TestimonialSliderThree";

class TestimonialThree extends Component {
  render() {
    return (
      <div className="testimonial_area_four" id="testimonial">
        <div className="container">
          <h2 className="title_text_new text-center">
            What say our {"\n"} Wonderfull Clients
          </h2>
          <TestimonialSliderThree />
        </div>
      </div>
    );
  }
}

export default TestimonialThree;
