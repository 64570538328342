import React, { Component } from "react";
import ProcessItem from "./Processitem";

class Process extends Component {
  render() {
    const processData = [
      {
        id: 1,
        icon: "blub.png",
        sText: "Create a Plan",
        sdescription:
          "Marijuana refers to the dried into into leaves  ther find into find make it maiinge Cannabis",
      },
      {
        id: 2,
        icon: "work.png",
        sText: "Start the Working",
        sdescription:
          "Marijuana refers to the dried into into leaves  ther find into find make it maiinge Cannabis",
      },
      {
        id: 3,
        icon: "hand.png",
        sText: "Hand over Work",
        sdescription:
          "Marijuana refers to the dried into into leaves  ther find into find make it maiinge Cannabis",
      },
      // Add more items as needed
    ];
    return (
      <section className="process_area">
        <div className="container">
          <h2 className="title_text_new text-center">
            How We Work Best {"\n"} Processing
          </h2>
          <div className="row justify-content-center">
            {processData.map((item) => (
              <ProcessItem key={item.id} {...item} />
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default Process;
