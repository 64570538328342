import React, { Component } from "react";
import Slider from "react-slick";

class TestimonialSliderThree extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
      autoplay: true,
      pauseOnHover: true,
      autoplaySpeed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div>
        <Slider {...settings} className="testimonial_slider">
          <div className="item">
            <div className="d-flex">
              <div className="author d-flex">
                <div className="author_img">
                  <img src={require("../../image/slider2.png")} alt="" />
                </div>
                <div className="author_name">
                  <h6>Alex Smith</h6>
                  <span>Envato Customer</span>
                </div>
              </div>
              <div className="content">
                <p>
                  There are many variations of passages of as Ipsum available,
                  but the majority have suffered alteration in some form, by
                  injected humour, or randomised into and to fine words.There
                  are many variations of passages of as Ipsum available, but the
                  majority have
                </p>
                <div className="ratting">
                  <i className="icon_star" />
                  <i className="icon_star" />
                  <i className="icon_star" />
                  <i className="icon_star " />
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="d-flex">
              <div className="author d-flex">
                <div className="author_img">
                  <img src={require("../../image/slider2.png")} alt="" />
                </div>
                <div className="author_name">
                  <h6>Hasfa Sultana</h6>
                  <span>Envato Customer</span>
                </div>
              </div>
              <div className="content">
                <p>
                  There are many variations of passages of as Ipsum available,
                  but the majority have suffered alteration in some form, by
                  injected humour, or randomised into and to fine words.There
                  are many variations of passages of as Ipsum available, but the
                  majority have
                </p>
                <div className="ratting">
                  <i className="icon_star" />
                  <i className="icon_star" />
                  <i className="icon_star" />
                  <i className="icon_star " />
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="d-flex">
              <div className="author d-flex">
                <div className="author_img">
                  <img src={require("../../image/slider2.png")} alt="" />
                </div>
                <div className="author_name">
                  <h6>Hasfa Sultana</h6>
                  <span>Envato Customer</span>
                </div>
              </div>
              <div className="content">
                <p>
                  There are many variations of passages of as Ipsum available,
                  but the majority have suffered alteration in some form, by
                  injected humour, or randomised into and to fine words.There
                  are many variations of passages of as Ipsum available, but the
                  majority have
                </p>
                <div className="ratting">
                  <i className="icon_star" />
                  <i className="icon_star" />
                  <i className="icon_star" />
                  <i className="icon_star " />
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}
export default TestimonialSliderThree;
