import React, { Component } from "react";

class ProcessItem extends Component {
  render() {
    let { classs, sText, sdescription, icon } = this.props;
    return (
      <div className="col-lg-4 col-sm-6">
        <div
          className={`process_item text-center wow fadeInUp ${classs}`}
          data-wow-delay="0.1s"
        >
          <div className="text">
            <div className="round">
              <img src={require(`../../image/${icon}`)} alt="icon" />
              <h3 className="t_color">{sText}</h3>
              <p>{sdescription}</p>
            </div>
          </div>
          <span></span>
        </div>
      </div>
    );
  }
}
export default ProcessItem;
